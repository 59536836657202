<template>
  <div class="bottom-view">
    <div class="centerBottom">
      <!-- <div>Copyright© 2024 Tech Science Press</div> -->
      <div>Academic Publishing Pte Ltd © 2019-2024 All Right Reserved</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom-view {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  background: #626262;
  color: #ffffff;
  @media (max-width: 996px) {
    width: 100vw;
    padding: 15px 0;
  }
  .centerBottom {
    width: 1400px;
    margin: auto;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    @media (max-width: 996px) {
      width: 100%;
      font-size: 13px;
      // height: 42px;
      line-height: 30px;
    }
  }
}
</style>
