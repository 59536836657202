<template>
  <div id="app" class="scrollable-container">
    <div class="app" v-if="$route.name !== 'login' && $route.name !== 'regist'">
      <HomeHeader :class="{ hidden: isHidden }" />
      <HomeNav :class="{ navPosition: navPosition }" />
      <router-view />
      <HomeFooter />
      <div class="toTop" v-show="showButton" @click="scrollToTop">
        <i class="el-icon-caret-top" style="font-size: 20px; color: #000"></i>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>
<script>
import HomeHeader from "./components/HomeHeader";
import HomeNav from "./components/HomeNav";
import HomeFooter from "./components/bottomView";
export default {
  name: "App",
  components: {
    HomeHeader,
    HomeNav,
    HomeFooter,
  },
  data() {
    return {
      isHidden: false,
      navPosition: false,
      showButton: false,
      isClosing: false,
    };
  },
  created() {},
  methods: {
    handleScroll() {
      if (window.innerWidth > 996) {
        const scrollTop = window.scrollY;
        this.isHidden = scrollTop > 95;
        this.navPosition = this.isHidden;
        this.showButton = scrollTop > 800;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("userInfo");
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less">
@import url("https://at.alicdn.com/t/c/font_4650831_qb2uq81rba.css");
* {
  margin: 0;
  padding: 0;
}
.app {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 350px);
  background-color: #f0f0f0;
  padding-top: 95px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  @media (max-width: 996px) {
    padding-top: 40px;
  }
}
.scrollable-container {
  flex: 1;
  .hidden {
    display: none; /* 隐藏导航栏 */
  }
  .navPosition {
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .toTop {
    width: 50px;
    height: 50px;
    background: #e1e1e1;
    border: 1px solid #bbb;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 80px;
  }
}
a {
  text-decoration: none;
  color: inherit;
}

/* 为所有具有滚动条的元素自定义滚动条样式 */
html {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  ::-webkit-scrollbar-track {
    background-color: #eee;
  }
  // scrollbar-width: thin; /* 设置滚动条宽度为较窄的宽度 */
  // scrollbar-color: #888 #f1f1f1; /* 设置滚动条滑块颜色和轨道颜色 */
}
li {
  // list-style: none;
}
</style>
