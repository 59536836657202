<template>
  <div class="header">
    <div class="header-center">
      <div
        id="menu"
        :class="{ navMenu: !isShow, minNnavShow: isShow }"
        @click="minNavShow"
      >
        <span class="a1"></span>
        <span class="a2"></span>
        <span class="a3"></span>
      </div>
      <div class="min-nav" v-if="isShow">
        <!-- v-if="isMobile" -->
        <ul>
          <li
            @click="menuShow"
            v-for="(item, index) in navList"
            :key="index"
            :class="{ select: item.path == $route.path }"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>

      <ul class="nav-tabs">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="{ select: item.path == $route.path }"
        >
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { post } from "@/utils/http";
export default {
  data() {
    return {
      navList: [
        { name: "Home", path: "/" },
        { name: "Journals", path: "/journalList" },
        {
          name: "Editorial and Publishing Policies",
          path: "/editorialPublishing",
        },
        // { name: "Partners", path: "/" },
        { name: "News & Announcements", path: "/announcementsList" },
        { name: "About", path: "/aboutView" },
      ],
      navLists: [
        { name: "Home", path: "/" },
        { name: "Journals", path: "/journalList" },
        {
          name: "menu",
          children: [
            {
              name: "Editorial and Publishing Policies",
              path: "/editorialPublishing",
            },
            // { name: "Partners", path: "/" },
            { name: "News & Announcements", path: "/announcementsList" },
            { name: "About", path: "/aboutView" },
          ],
        },
      ],
      isShow: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    // isMobile() {
    //   return this.windowWidth <= 600;
    // },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    menuShow() {
      console.log("menuShow");
      this.isShow = false;
      // document.querySelector("#menu").classList.toggle("navMenu");
    },
    minNavShow() {
      this.isShow = !this.isShow;
      console.log(this.isShow);
      // if (this.isShow) {
      //   console.log("minNavShow");
      //   document.querySelector("#menu").classList.toggle("min-nav-show");
      // } else {
      //   console.log("minNavHide");
      //   document.querySelector("#menu").classList.toggle("navMenu");
      // }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getList() {
      post("/index/api/v1/stats/sitePage", {
        path: window.location.pathname,
      }).then();
      // post(
      //   "/index/api/v1/stats/sitePage?path=" + window.location.pathname
      // ).then();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/iconfont/iconfont.css";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 50px;
  position: fixed;
  top: 45px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 4px 4px 4px rgba(195, 195, 195, 0.35);
  z-index: 1000;
  @media (max-width: 996px) {
    display: none;
    // position: fixed !important;
    // top: 45px;
    // left: 0;
    // width: 100vw;
    // padding: 0 5px;
    // box-sizing: border-box;
  }
  .header-center {
    width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 996px) {
      width: 100vw;
      justify-content: flex-end;
    }
    .nav-tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 996px) {
        // width: 100%;
        display: none;
      }
      li {
        list-style: none;
        cursor: pointer;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        position: relative;
        font-weight: bold;
        font-family: "Arial", "Helvetica", sans-serif; /* 设置字体为黑体 */
      }
      li:after {
        content: "";
        width: 0;
        height: 3px;
        background-color: #a40000;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
      }
      li:hover:after {
        width: 100%;
      }
      li:hover {
        color: #a40000;
      }
      .select {
        color: #a40000;
      }
      .select:after {
        width: 100%;
      }
    }
    .navMenu {
      display: none;
      width: 30px;
      position: relative;
      @media (max-width: 996px) {
        display: block;
      }
      span {
        background-color: #004fa3;
        display: block;
        width: 30px;
        height: 2px;
        position: absolute;
        transition: all 0.5s;
      }
      .a1 {
        top: 0;
        left: 0;
      }
      .a2 {
        display: block;
        top: 8px;
        left: 0;
      }
      .a3 {
        top: 16px;
        left: 0;
      }
    }
    .minNnavShow {
      width: 30px;
      position: relative;
      z-index: 3000;
      span {
        height: 2px;
        background-color: #004fa3;
        display: block;
        position: absolute;
        transition: all 0.5s;
      }
      .a1 {
        top: 8px;
        width: 30px;
        transform: rotate(45deg);
      }
      .a2 {
        display: none;
      }
      .a3 {
        top: 8px;
        width: 30px;
        transform: rotate(135deg);
      }
    }
    .min-nav {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: #ffffff;
      transform: all 0.5s;
      z-index: 999;
      li {
        width: 100%;
        list-style: none;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        &:hover {
          background-color: #f19600;
        }
        &:active {
          background-color: #f19600;
        }
      }
    }
  }
}
</style>
